import React from "react";
import { SEO } from "@bluefin/components";
import { Grid, Segment, Embed } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class BookNowPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          ogUrl={"https://www.salonsolei.com/book-now/"}
        />
        <Grid
          className={"custom-page-book-now custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"qcjvunmrreopfhiu"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"top"}
            columns={1}
          >
            <Grid.Column
              className={"locuggbsdwesynqx"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <React.Fragment
                  key={"JSON__bbe9199e-249d-43b3-8bb4-2124922a2af5"}
                >
                  <Embed
                    url={
                      "https://na01.envisiongo.com/a/SALONSOLEI/OnlineBookingFrame.aspx"
                    }
                    active={true}
                    className={"booking-iframe"}
                  />
                </React.Fragment>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 30530 }) {
      title
      seoTitle
      description
    }
  }
`;
